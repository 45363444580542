







































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'Chem1301Lab3Discussion2',
  components: {STextarea, TableInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        step5: null,
        step6: null,
        step7: null,
        step8: null,
        step9: null,
        step10: null,
        step11: null,
        step12: null,
      },
      rows: [
        {step: '5', input: 'step5'},
        {step: '6', input: 'step6'},
        {step: '7', input: 'step7'},
        {step: '8', input: 'step8'},
        {step: '9', input: 'step9'},
        {step: '10', input: 'step10'},
        {step: '11', input: 'step11'},
        {step: '12', input: 'step12'},
      ],
    };
  },
});
